import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import EspaciadorVertical from '../components/espaciador-vertical'
import { Relatos } from '../components/estilos/estilos-de-articulo'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allAnecdotasRoma1Yaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const AnecdotasRoma1 = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allAnecdotasRoma1Yaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Relatos>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogrbienvenidos1encastelsantangelojpg')}
                title="En el Castel Sant’Angelo. Roma."
                leyenda="En el Castel Sant’Angelo. Roma."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>¡Roma, il mio amore!</h2>
                <p>
                  ¡Hola! Te cuento una historia ¡MI HISTORIA! Hice más de 20 viajes a Roma ¿Pero
                  esta chica está loca? ¿Siempre a Roma y ningún otro lugar? ¡Que estoy medio
                  "rayada" y ... puede ser! Pero tengo "atenuantes" ...
                </p>
                <p>
                  Había ido en tres oportunidades con mis padres y siempre quise volver porque quedé
                  enamorada de Roma. Pasó el tiempo y ese deseo quedó como un sueño más. Entre
                  paréntesis: la foto de la página de inicio corresponde a mi primer viaje a Roma.
                  Fue tomada por un fotógrafo en el zoológico de Roma (amo a los leones). Aprender a
                  bailar el tango ¿por qué? Lo único que atiné a hacer para realizar mi sueño fue
                  aprender a bailar el tango! Y sí!!! ¿Qué tendrá que ver? Y…, tiene que ver. Cuando
                  iba a cenar a restaurantes típicos en Roma y había cantantes se enteraban que
                  éramos argentinos se ponían a cantar tangos y nos decían que bailáramos, dando por
                  supuesto que todo argentino sabe bailar el tango, yo me quedaba mirando, muy
                  frustrada ¡Qué bajón! Así que para mi gran retorno a Roma tenía que convertirme en
                  una bailarina de tango…bueh, a no exagerar! Así fue que tomé clases de tango por
                  todo Buenos Aires. Y me encantaba. ¡Llegó el 29 de febrero! ¡Sí ,año bisiesto!
                  Estaba en La Viruta. Después de mi clase de tango me solía quedar un ratito a
                  bailar, generalmente con mis compañeros, para practicar un poco. Estaba parada
                  mirando cómo bailaban cuando se acercó un hombre y me invitó a bailar. Le dije que
                  sí y le aclaré que era de nivel inicial. Siempre hacía así porque a los que saben
                  bailar no les gusta nada de nada bailar con una principiante. Mientras bailábamos
                  sentí que él marcaba el ritmo diciendo: “uno, due, tre, quattro”. No me llamó
                  mucho la atención. Pero después de un rato me di cuenta que en realidad ¡era
                  italiano! Entonces traté de recurrir a mis escasísimos conocimientos de italiano
                  para comunicarme. Le entendía sin problemas pero hablar… es otra cosa. Me ofreció
                  tomar algo. Después me invitó a su mesa para ver un mapa y charlar. Era un turista
                  italiano que hacía tiempo que estaba en Buenos Aires porque había venido a bailar
                  el Tango. Y se iba a quedar unos 13 días más antes de volver a su Roma natal. La
                  cuestión es que me pidió que lo acompañara al día siguiente, sábado, a almorzar y
                  después a caminar por Florida porque tenía que comprar regalos para su familia.
                  Era viudo……. ¡Volví a Roma invitada por él! ¡Ya me imagino que estás saltando en
                  una pata! ¡Qué historia de amor maravillosa!!!! NO, no, no, ja, ja nada de
                  maravillosa. Duró tres años pero terminé enferma, violencia psicológica sobre mi
                  pobre persona. Pero lo que me dejó de bueno, a parte de la experiencia, es que
                  gracias a él volví a Roma.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                {/* <h3>Título</h3> */}
                <p>
                  Para visitarlo viajaba dos veces al año, en las vacaciones de verano y de invierno
                  de la Facultad, salvo una vez que él vino a Buenos Aires, en total hice cinco
                  viajes. ¡Buenísimo! Siempre es bueno viajar pero no es que conociera mucho Roma
                  con este novio. El Lungotevere en el verano, piazza San Pietro, girar casi
                  corriendo alrededor del Coliseo, casi nada de nada. Él andaba en auto solamente,
                  no había dónde estacionar y no quería que yo me comportara como turista ¡La
                  primera semana me llevó tres veces al dentista! Horas esperando y seguimos yendo
                  al dentista las demás semanas y médicos y hospitales! ¡ Imperdonable! ¿Cómo volví
                  a Roma por cuenta mía? Pero vayamos a la conclusión. Una vez que cortamos me di
                  cuenta que yo podía ir a Roma, era cuestión de organizarme. Y ocurrió el milagro,
                  mi mamá le ganó un juicio al estado y me regaló parte de lo ganado.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                {/* <h3>Título</h3> */}
                <p>
                  ¡Ya tenía el dinero para ir a Roma! Decidí invertirlo en hacer mi viaje soñado.
                  Había estado 5 veces en Italia en los últimos 3 años y había conocido muy poco y
                  lo peor es que no lo había pasado bien ¡Ahora a organizar todo yo sola y a
                  divertirme! El siguiente año bisiesto me encontró en Italia. El 27 de febrero
                  llegué a Bari, comenzando mi recorrido de 28 maravillosos días. La idea era hacer
                  todo lo que no había podido hacer con mi tacaño y amargo novio. Estoy pensando que
                  los años bisiestos me traen buena suerte ¿Por qué? Y no solamente porque logré
                  realizar mi sueño…misterio…….¿qué sucedió? ¡Paciencia! Será tema de otro post.
                  Susana Canel en el Castel Sant'Angelo. En el Castel Sant’Angelo. Roma. Susana
                  Marta Canel en la playa de Fiumicino. En la playa de Fiumicino. Etiquetas
                  Fiumicino playa Categorías Roma ¡Bienvenidos! Autor de la entrada Por Susana Fecha
                  de la entrada abril 10, 2018 No hay comentarios en ¡Bienvenidos!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            {/* <Minipagina>
              <FotoConLeyenda
                zoom
                src=""
                title="Cefalú, playa y botes."
                leyenda="Cefalù. Piazza Garibaldi. Orologio."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                src=""
                title="Cefalú, playa y botes."
                leyenda="Cefalù. Piazza Garibaldi. Orologio."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                src=""
                title="Cefalú, playa y botes."
                leyenda="Cefalù. Piazza Garibaldi. Orologio."
              />
            </Minipagina> */}
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Relatos>
    </Marco>
  )
}

export default AnecdotasRoma1
